import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { key: 0 }

import { useStepper } from '@/entityes/invoice/useStepper'
import { DeclarationAction } from '@/entityes/invoice/declaration/declaration.type'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { computed, watch, onUnmounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import ClearLoader from '@/07-Shared/ui/ClearLoader.vue'
import LeftSideBar from '@/07-Shared/ui/LeftSideBar.vue'
import InvoiceSidebarMenu from '@/components/Invoice/InvoiceSidebarMenu.vue'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import InvoiceErrWarning from '@/07-Shared/ui/InvoiceErrWarning.vue'
import AdminInvoiceRemote from '@/05-Features/AdminInvoiceRemote/ui/AdminInvoiceRemote.vue'
import usePolitic from '@/use/usePolitic'
import { useWs } from '@/socket/ws'
import useNotificationsChannel from '@/socket/channels/useNotificatioinsChannel'
import MobileMenu from '@/07-Shared/ui/MobileMenu.vue'
import NGThankUPage from '@/components/NGThankUPage.vue'
import useWindowWidth from '@/use/useWindowWidth'


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceView',
  setup(__props) {

const invoiceRepository = useInvoiceRepository()
const stepper = useStepper()
const { initAsync, hasTimer } = useAsyncInvoiceReload()
const { isAdmin } = usePolitic()
const { screenLessValue } = useWindowWidth()

function init(id: string | string[]) {
  invoiceRepository
    .getInvoice(id as string)
    .then((inv) => {
      return initAsync(inv)
    })
    .then((res) => {
      const haveTms = res.tms
        ? stepper.checkingForTms(
            Object.entries(res.tms)
              .filter((item) => item[1])
              .map((item) => item[0] as DeclarationAction)
          )
        : false
      stepper.selectStep(res.status.invoice_status, haveTms)
    })
}

const route = useRoute()
const invoiceId = computed(() => route.params.id as string)
watch(invoiceId, init, { immediate: true })
const invoiceStore = useInvoiceStore()

const ws = useWs()
function socketInit() {
  ws.open()
  useNotificationsChannel(ws)
}

socketInit()
onUnmounted(() => {
  ws.close()
})

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_row, { gutter: 10 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          xs: 24,
          md: 24,
          lg: 4
        }, {
          default: _withCtx(() => [
            (_unref(screenLessValue)(1201))
              ? (_openBlock(), _createBlock(MobileMenu, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(InvoiceSidebarMenu, {
                      "invoice-id": invoiceId.value,
                      "show-loader": _unref(invoiceRepository).getInvoice.indicator.value,
                      "invoice-payed": _unref(invoiceStore).invoice?.status.invoice_status === 'payed'
                    }, null, 8, ["invoice-id", "show-loader", "invoice-payed"])
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(LeftSideBar, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(InvoiceSidebarMenu, {
                      "invoice-id": invoiceId.value,
                      "show-loader": _unref(invoiceRepository).getInvoice.indicator.value,
                      "invoice-payed": _unref(invoiceStore).invoice?.status.invoice_status === 'payed'
                    }, null, 8, ["invoice-id", "show-loader", "invoice-payed"])
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 20,
          class: "py-4"
        }, {
          default: _withCtx(() => [
            (!!_unref(invoiceStore).invoice && _unref(isAdmin))
              ? (_openBlock(), _createBlock(AdminInvoiceRemote, {
                  class: "mb-8",
                  key: _unref(invoiceStore).invoice?.id
                }))
              : _createCommentVNode("", true),
            _createVNode(InvoiceErrWarning),
            _withDirectives(_createVNode(ClearLoader, null, null, 512), [
              [_vShow, _unref(hasTimer) || _unref(invoiceStore).invoice?.status.async]
            ]),
            _withDirectives(_createVNode(_component_RouterView, null, null, 512), [
              [_vShow, !(_unref(hasTimer) || _unref(invoiceStore).invoice?.status.async)]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (!!_unref(invoiceStore).invoice)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(NGThankUPage, {
            invoice: _unref(invoiceStore).invoice
          }, null, 8, ["invoice"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})