<script setup lang="ts">
import { AddedFiles, DeletedFiles, FileTagType, LoaderDescriberType } from '@/06-Entities/File'

interface IProps {
  describer: LoaderDescriberType
}

const props = defineProps<IProps>()
interface IEmits {
  (
    e: 'removeDeletedFile',
    value: {
      index: number
      serverTag: FileTagType
    }
  ): void // desc.onRemoveDeleteFile
  (
    e: 'removeLocalFile',
    value: {
      index: number
      serverTag: FileTagType
    }
  ): void
}

const emit = defineEmits<IEmits>()
</script>

<template>
  <div class="p-4 bg-gray">
    <h3>Портфель</h3>
  </div>
  <div class="p-4" style="min-height: 100%">
    <div v-if="describer.hasLocalFiles.value || describer.hasFileToDelete.value" class="mb-8">
      <!-- Добавленные файлы-->
      <div v-for="desc in props.describer.describers" :key="'show_' + desc.config.serverTag">
        <div
          class="font-weight--extra flex items-center mb-4"
          v-if="desc.deleteFiles.length > 0 || desc.localFiles.file_names.length > 0"
        >
          <img class="mr-2" :src="desc?.config.logo" :width="24" alt="broker-logo" />
          <p>{{ desc.config.title }}</p>
        </div>
        <!-- Новые файлы -->
        <AddedFiles
          :files="desc.localFiles.file_names"
          @delete-loaded-file="
            (index: number) => {
              emit('removeLocalFile', { index, serverTag: desc.serverTag as FileTagType })
            }
          "
        />
        <!-- Удаляемые файлы-->
        <DeletedFiles
          :files="desc.getFileToDelete || []"
          @delete-file="(index: number) => emit('removeDeletedFile', { index, serverTag: desc.serverTag as FileTagType })"
        />
      </div>
    </div>
    <el-empty description="нет изменений" v-else />
  </div>
</template>

<style lang="sass" scoped></style>
