<script setup lang="ts">
import { Menu, PhoneFilled } from '@element-plus/icons-vue'
import Finder from '@/07-Shared/ui/Finder.vue'
import usePolitic from '@/use/usePolitic'
import useMenuDescription from '@/describers/MenuDescriber'
import { ref } from 'vue'
import NgInvoiceVideo from '@/components/NgInvoiceVideo.vue'

const { isAdmin, isPartner } = usePolitic()
const menuIsOpen = ref(false)
const { accountMenuDescriptor, phone } = useMenuDescription()
const invoiceVideo = ref(null)
</script>

<template>
  <div class="bg-gray mb-12 p-4">
    <div class="flex justify-between items-center w-full">
      <div>
        <router-link to="/" class="mb-4">
          <div>
            <img src="@/assets/new_logo.svg" alt="logo" width="150" />
          </div>
        </router-link>
      </div>
      <el-icon @click="menuIsOpen = !menuIsOpen" size="large"><Menu /></el-icon>
    </div>
    <el-drawer @click="menuIsOpen = false" size="50%" class="bg-gray" v-model="menuIsOpen" direction="ttb">
      <template #header>
        <div>
          <router-link to="/" class="mb-4">
            <div>
              <img src="@/assets/new_logo.svg" alt="logo" width="150" />
            </div>
          </router-link>
        </div>
      </template>
      <div>
        <div>
          <a :href="`tel:+${phone}`">
            <div class="mb-4 clickable flex items-center">
              <el-icon :size="18">
                <PhoneFilled />
              </el-icon>
              <p>{{ phone }}</p>
            </div>
          </a>
        </div>
      </div>
      <Finder v-if="isAdmin || isPartner" />
      <slot></slot>
      <div
        v-for="item in accountMenuDescriptor"
        class="flex items-center clickable cursor-point mb-4"
        @click="item.clickAction"
      >
        <el-icon :size="18" class="mr-2">
          <component :is="item.icon" />
        </el-icon>
        <p>
          {{ item.title }}
        </p>
      </div>
    </el-drawer>
  </div>
  <NgInvoiceVideo
    ref="invoiceVideo"
    :show-button="false"
    :video-link="'https://www.youtube.com/embed/o3S17yaUvTI?enablejsapi=1'"
  />
</template>

<style scoped lang="sass"></style>
