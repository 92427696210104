import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "p-4",
  style: {"min-height":"100%"}
}
const _hoisted_2 = {
  key: 0,
  class: "mb-8"
}
const _hoisted_3 = {
  key: 0,
  class: "font-weight--extra flex items-center mb-4"
}
const _hoisted_4 = ["src"]

import { AddedFiles, DeletedFiles, FileTagType, LoaderDescriberType } from '@/06-Entities/File'

interface IProps {
  describer: LoaderDescriberType
}

interface IEmits {
  (
    e: 'removeDeletedFile',
    value: {
      index: number
      serverTag: FileTagType
    }
  ): void // desc.onRemoveDeleteFile
  (
    e: 'removeLocalFile',
    value: {
      index: number
      serverTag: FileTagType
    }
  ): void
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FileChangedView',
  props: {
    describer: {}
  },
  emits: ["removeDeletedFile", "removeLocalFile"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

return (_ctx: any,_cache: any) => {
  const _component_el_empty = _resolveComponent("el-empty")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "p-4 bg-gray" }, [
      _createElementVNode("h3", null, "Портфель")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.describer.hasLocalFiles.value || _ctx.describer.hasFileToDelete.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.describer.describers, (desc) => {
              return (_openBlock(), _createElementBlock("div", {
                key: 'show_' + desc.config.serverTag
              }, [
                (desc.deleteFiles.length > 0 || desc.localFiles.file_names.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("img", {
                        class: "mr-2",
                        src: desc?.config.logo,
                        width: 24,
                        alt: "broker-logo"
                      }, null, 8, _hoisted_4),
                      _createElementVNode("p", null, _toDisplayString(desc.config.title), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_unref(AddedFiles), {
                  files: desc.localFiles.file_names,
                  onDeleteLoadedFile: 
            (index) => {
              emit('removeLocalFile', { index, serverTag: desc.serverTag as FileTagType })
            }
          
                }, null, 8, ["files", "onDeleteLoadedFile"]),
                _createVNode(_unref(DeletedFiles), {
                  files: desc.getFileToDelete || [],
                  onDeleteFile: (index) => emit('removeDeletedFile', { index, serverTag: desc.serverTag as FileTagType })
                }, null, 8, ["files", "onDeleteFile"])
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createBlock(_component_el_empty, {
            key: 1,
            description: "нет изменений"
          }))
    ])
  ], 64))
}
}

})