<script setup lang="ts">
import { PreProcessingFiles } from '@/entityes/invoice/file/loader/loader.type'
import RightSideBar from '@/07-Shared/ui/RightSideBar.vue'
import { DragAndDrop, FileItem, FileFromServerItem, FileFromServerDeleted } from '@/06-Entities/File'
import { computed, defineAsyncComponent } from 'vue'
import { FileLoaderType } from '@/06-Entities/File'
const IbDownloadFileInstruction = defineAsyncComponent(
  () => import('@/04-Widgets/LoadFileStep/ui/IbDownloadFileInstruction.vue')
)

interface IProps {
  loaderDescriber: FileLoaderType
  showDeleteButton?: boolean
}
const showSidebar = defineModel<boolean>('showSidebar', { required: true, default: false })

const props = withDefaults(defineProps<IProps>(), { showDeleteButton: false })
const isExanteLoaderDescriber = computed<boolean>(
  () => props.loaderDescriber.config.serverTag !== 'exante_virtual_files'
)
interface IEmits {
  (e: 'readedFile', value: PreProcessingFiles): void
  (e: 'deleteLocalFile', index: number): void
  (e: 'deleteServerFile', value: number): void
  (e: 'undoDeleteFile'): void
  (e: 'saveFiles'): void
}

const emit = defineEmits<IEmits>()

function dndReadedFiles(value: PreProcessingFiles) {
  emit('readedFile', value)
}
function fileItemDeleteLocalLoadedItem(index: number) {
  emit('deleteLocalFile', index)
} // props.loaderDescriber.onRemoveLocalFile(index)
// function undoDeleteFile() {
//   emit('undoDeleteFile')
// }
// const showFileFromServer = computed(() => isExanteLoaderDescriber && props.loaderDescriber.filesFromServer.length > 0)
</script>

<template>
  <RightSideBar v-model="showSidebar">
    <div class="bg-gray">
      <div class="flex items-center mb-4 justify-center">
        <img :src="props.loaderDescriber.config.logo" alt="plus_icon" class="logo-img mr-2" />
        <h3 v-if="props.loaderDescriber.config.serverTag === 'exante_virtual_files'">Инструкция для клиентов Exante</h3>
        <h3 v-else>Загрузить отчеты {{ props.loaderDescriber.config.title }}</h3>
      </div>
      <p class="pl-4">{{ props.loaderDescriber.config.description }}</p>
    </div>
    <div class="px-4" v-if="isExanteLoaderDescriber">
      <DragAndDrop
        :text="props.loaderDescriber.config.text"
        :file-format="props.loaderDescriber.config.fileFormat"
        @readed-files="dndReadedFiles"
      />
      <div class="py-2">
        <FileItem
          v-for="(item, index) in props.loaderDescriber.localFiles.file_names"
          :name="item"
          :key="'local_files_' + item"
          @close="
            () => {
              fileItemDeleteLocalLoadedItem(index)
            }
          "
        />
      </div>
    </div>
    <div v-else class="p-4">
      <iframe
        src="https://www.youtube.com/embed/S2wMoKIKWfk?si=lwHLZJVjyba8nNBC"
        title="YouTube video player"
        width="100%"
        height="300px"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        loading="lazy"
      >
      </iframe>
    </div>
    <!--    &lt;!&ndash;Файлы присутствующие в заказе&ndash;&gt;-->
    <!--    <div v-if="showFileFromServer" class="my-4">-->
    <!--      <div class="bg-gray p-4 mb-4">-->
    <!--        <h2 class="mb-4 text-center">В портфеле</h2>-->
    <!--        <p v-if="!props.showDeleteButton" class="text-center">-->
    <!--          Вы можете удалить отчет, обратившись в службу поддержки.-->
    <!--        </p>-->
    <!--        <p v-else>-->
    <!--          Вы можете удалить отчет, нажав на значок мусорной корзины, затем – на кнопку «Изменить состав файлов». Далее-->
    <!--          нажмите «Продолжить» для обновления информации по портфелю.-->
    <!--        </p>-->
    <!--      </div>-->
    <!--      <FileFromServerItem-->
    <!--        :files="props.loaderDescriber.getFilesFromServer"-->
    <!--        :no-delete="!props.showDeleteButton"-->
    <!--        @delete="(index) => emit('deleteServerFile', index)"-->
    <!--      />-->
    <!--      &lt;!&ndash;Файлы присутствующие в заказе и отмеченные как удаленные&ndash;&gt;-->
    <!--      <div class="px-4">-->
    <!--        <FileFromServerDeleted :files="props.loaderDescriber.getFileToDelete" />-->
    <!--      </div>-->
    <!--    </div>-->

    <!-- Кнопки подтверждения изменений или отмены -->
    <div
      class="p-4 flex items-center"
      v-if="props.loaderDescriber.deleteFiles.length > 0 || props.loaderDescriber.localFiles.file_names.length > 0"
    >
      <el-button
        @click="
          () => {
            showSidebar = false
            emit('saveFiles')
          }
        "
        class="button-main"
        type="primary"
      >
        <!--        {{-->
        <!--          props.loaderDescriber.getFilesFromServer.length > 0 || props.loaderDescriber.deleteFiles.length > 0-->
        <!--            ? 'Сохранить изменения файлов'-->
        <!--            : 'Сохранить файлы'-->
        <!--        }}-->
        Сохранить
      </el-button>
      <!--      <el-button-->
      <!--        @click="undoDeleteFile"-->
      <!--        v-if="props.loaderDescriber.deleteFiles.length > 0"-->
      <!--        type="primary"-->
      <!--        class="button-main"-->
      <!--      >-->
      <!--        <img src="@/assets/undo_icon.svg" width="14" alt="undo_icon" />-->
      <!--      </el-button>-->
    </div>
    <ib-download-file-instruction :broker="props.loaderDescriber?.config?.serverTag" />
  </RightSideBar>
</template>

<style lang="sass" scoped></style>
