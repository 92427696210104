<script lang="ts" setup>
import { computed, onBeforeMount, ref } from 'vue'
import useGtagManager from '@/use/useGtagManager'
import { ElNotification } from 'element-plus'
import { IInvoice } from '@/entityes/invoice/invoice.type'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useUserStore } from '@/entityes/user/userStore'
import useColors from '@/07-Shared/model/useColors'

interface IProps {
  invoice: IInvoice
}
const props = defineProps<IProps>()
const thankUPage = computed(() => props.invoice?.thank_you_page)
const userStore = useUserStore()
const payedStatus = computed(() => props.invoice?.status.invoice_status === 'payed')
const { dropThankUPage } = useInvoiceRepository()
const visible = ref(false)
const way = computed(() => Object.keys(props.invoice?.pre_selected_add_incomes).length > 0)

const { event } = useGtagManager()
const { colors, isDark } = useColors()
const openModal = () => {
  visible.value = true
}
const closeModal = () => {
  visible.value = false
}
const chooseYourWay = () => {
  if (userStore.user?.role === 'customer' || userStore.user?.role === 'affiliate') {
    event('purchase', {
      transaction_id: props.invoice.id,
      value: props.invoice.outSum,
      currency: 'RUB',
      goods: [...Object.keys(props.invoice?.goods)],
      promocode: Object.keys(props.invoice).includes('promo'),
    })
  }
  dropThankUPage(props.invoice.id, { has_additional: way.value })
    .then((res) => {
      console.log(res)
      closeModal()
      ElNotification({
        title: 'Спасибо',
        message: 'Можете продолжить оформление документов',
        type: 'success',
      })
    })
    .catch((err) => {
      console.log(err)
      ElNotification({
        title: 'Ошибка',
        message: 'Ошибка выполнения запроса, попробуйте еще раз',
        type: 'error',
      })
    })
}
onBeforeMount(() => {
  if (thankUPage.value && payedStatus.value) openModal()
})
</script>

<template>
  <el-dialog
    v-model="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :lock-scroll="true"
    :show-close="false"
    center
    :align-center="true"
    width="60%"
  >
    <div class="p-8">
      <h1 class="mb-8">Спасибо за оплату!</h1>
      <div>
        <div class="mb-4 text-center" style="word-break: break-word; hyphens: auto">
          <div class="mb-4 flex-box">
            <el-icon :size="20" class="mr-2" :color="isDark ? colors.dark.ng_purple : colors.light.ng_purple">
              <SuccessFilled />
            </el-icon>
            <p class="mb-4">Ваш платеж успешно принят.</p>
          </div>
          <div class="mb-4 flex">
            <el-icon
              :size="20"
              class="margin-h--right-6"
              :color="isDark ? colors.dark.ng_purple : colors.light.ng_purple"
            >
              <Message />
            </el-icon>
            <p class="mb-4">В ближайшее время вы получите письмо о ходе выполнения заказа.</p>
          </div>
          <div class="mb-4 flex">
            <el-icon
              :size="20"
              class="margin-h--right-6"
              :color="isDark ? colors.dark.ng_purple : colors.light.ng_purple"
            >
              <DocumentCopy />
            </el-icon>
            <p class="mb-4">Возможно, мы попросим дополнительные документы для успешного формирования заказа.</p>
          </div>
        </div>
        <div class="flex items-center">
          <el-button @click="chooseYourWay" size="large" style="width: 300px" type="success" class="button-main">
            Продолжить
          </el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<style scoped lang="sass"></style>
