import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent } from "vue"
import _imports_0 from '@/assets/new_logo.svg'


const _hoisted_1 = { class: "bg-gray mb-12 p-4" }
const _hoisted_2 = { class: "flex justify-between items-center w-full" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "mb-4 clickable flex items-center" }
const _hoisted_5 = ["onClick"]

import { Menu, PhoneFilled } from '@element-plus/icons-vue'
import Finder from '@/07-Shared/ui/Finder.vue'
import usePolitic from '@/use/usePolitic'
import useMenuDescription from '@/describers/MenuDescriber'
import { ref } from 'vue'
import NgInvoiceVideo from '@/components/NgInvoiceVideo.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'MobileMenu',
  setup(__props) {

const { isAdmin, isPartner } = usePolitic()
const menuIsOpen = ref(false)
const { accountMenuDescriptor, phone } = useMenuDescription()
const invoiceVideo = ref(null)

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createVNode(_component_router_link, {
            to: "/",
            class: "mb-4"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "logo",
                  width: "150"
                })
              ], -1)
            ])),
            _: 1
          })
        ]),
        _createVNode(_component_el_icon, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (menuIsOpen.value = !menuIsOpen.value)),
          size: "large"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(Menu))
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_el_drawer, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (menuIsOpen.value = false)),
        size: "50%",
        class: "bg-gray",
        modelValue: menuIsOpen.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((menuIsOpen).value = $event)),
        direction: "ttb"
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_router_link, {
              to: "/",
              class: "mb-4"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("div", null, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: "logo",
                    width: "150"
                  })
                ], -1)
              ])),
              _: 1
            })
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createElementVNode("a", {
                href: `tel:+${_unref(phone)}`
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_el_icon, { size: 18 }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(PhoneFilled))
                    ]),
                    _: 1
                  }),
                  _createElementVNode("p", null, _toDisplayString(_unref(phone)), 1)
                ])
              ], 8, _hoisted_3)
            ])
          ]),
          (_unref(isAdmin) || _unref(isPartner))
            ? (_openBlock(), _createBlock(Finder, { key: 0 }))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default"),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(accountMenuDescriptor), (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "flex items-center clickable cursor-point mb-4",
              onClick: item.clickAction
            }, [
              _createVNode(_component_el_icon, {
                size: 18,
                class: "mr-2"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon)))
                ]),
                _: 2
              }, 1024),
              _createElementVNode("p", null, _toDisplayString(item.title), 1)
            ], 8, _hoisted_5))
          }), 256))
        ]),
        _: 3
      }, 8, ["modelValue"])
    ]),
    _createVNode(NgInvoiceVideo, {
      ref_key: "invoiceVideo",
      ref: invoiceVideo,
      "show-button": false,
      "video-link": 'https://www.youtube.com/embed/o3S17yaUvTI?enablejsapi=1'
    }, null, 512)
  ], 64))
}
}

})