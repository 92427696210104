<script setup lang="ts">
import SplitWindow from '@/07-Shared/ui/SplitWindow.vue'
import { useLoaderDescriber } from '../model'
import { BrokersAndLoadFiles } from '@/05-Features/FileLoader'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { FileChangedView } from '@/05-Features/FileChangedView'
import { SidebarLoader } from '@/05-Features/SidebarLoader'
import { computed, ref, watch } from 'vue'
import { FileTagType } from '@/06-Entities/File'
import NgInvoiceVideo from '@/components/NgInvoiceVideo.vue'
import { useSendFileRequest } from '../api/sendFile.request'
import { useStepper } from '@/entityes/invoice/useStepper'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { UploadFilled } from '@element-plus/icons-vue'
import { ElNotification } from 'element-plus'

interface IProps {
  invoiceId: number | string
}

const props = defineProps<IProps>()
const { loaderDescriber, selectActiveLoader, activeLoader } = useLoaderDescriber()
const invoiceStore = useInvoiceStore()
const invoice = computed(() => invoiceStore.invoice)

watch(invoice, () => {
  if (invoiceStore.invoice) {
    loaderDescriber.mergeFile(invoiceStore.invoice.broker_files)
  }
})
if (invoiceStore.invoice) {
  loaderDescriber.mergeFile(invoiceStore.invoice.broker_files)
}
const showSidebar = ref(true)
function openModal(serverTag: FileTagType) {
  selectActiveLoader(serverTag)
  showSidebar.value = true
}
const { loadFile } = useSendFileRequest()
const { selectStep } = useStepper()
const { initAsync } = useAsyncInvoiceReload()
function sendFiles() {
  loadFile(props.invoiceId, loaderDescriber.toJson())
    .then((invoice) => {
      invoiceStore.invoice = invoice
      initAsync(invoice).then((res) => {
        loaderDescriber.describers.forEach((loader) => {
          loader.localFiles.files.splice(0, loader.localFiles.files.length)
          loader.localFiles.file_names.splice(0, loader.localFiles.file_names.length)
        })
        selectStep(res.status.invoice_status)
      })
    })
    .catch((err) => {
      ElNotification({
        title: `Ошибка`,
        message: `загрузки файлов. Попробуйте еще раз. ${err}`,
        type: 'error',
      })
    })
}
useInvoiceRepository()
  .getInvoice(props.invoiceId)
  .then((res) => {
    initAsync(res)
  })
</script>

<template>
  <div class="container-list">
    <div class="flex items-center mb-4">
      <h1 class="mr-8">Шаг 1. Загрузите отчеты брокеров</h1>
      <ng-invoice-video
        ref="invoiceVideo"
        :show-button="true"
        :video-link="'https://www.youtube.com/embed/2wq-EYaR5R0?si=sknCrVbJzS6JlskK'"
      />
    </div>
    <div class="mb-4">
      <p class="mb-2">Нажмите на название брокера и следуйте инструкции по правильному скачиванию отчета брокера.</p>
      <p>Наличие других доходов и вычетов можно указать на следующей странице.</p>
    </div>
    <SplitWindow>
      <template v-slot:left>
        <BrokersAndLoadFiles
          :invoice-id="props.invoiceId"
          :loader-describer="loaderDescriber"
          @delete-file-from-server="loaderDescriber.deleteFromServer"
          @select-loader="openModal"
        />
      </template>
      <template v-slot:right>
        <FileChangedView
          :describer="loaderDescriber"
          @remove-deleted-file="loaderDescriber.removeDeleteFile"
          @remove-local-file="loaderDescriber.removeLocalFile"
        />
        <div class="p-4">
          <el-button
            class="send-files-button button-main"
            type="success"
            :loading="loadFile.indicator.value"
            @click="sendFiles"
            :icon="UploadFilled"
            v-if="loaderDescriber.hasLocalFiles.value || loaderDescriber.hasFileToDelete.value"
          >
            Продолжить
          </el-button>
        </div>
      </template>
    </SplitWindow>
    <div v-if="activeLoader">
      <SidebarLoader
        v-model:show-sidebar="showSidebar"
        :loader-describer="activeLoader"
        :show-delete-button="true"
        @readed-file="activeLoader.onUpdateFilesEvent"
        @delete-local-file="activeLoader.onRemoveLocalFile"
        @delete-server-file="activeLoader.onAddDeleteFile"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped></style>
