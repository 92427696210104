import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "bg-gray" }
const _hoisted_2 = { class: "flex items-center mb-4 justify-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "pl-4" }
const _hoisted_7 = {
  key: 0,
  class: "px-4"
}
const _hoisted_8 = { class: "py-2" }
const _hoisted_9 = {
  key: 1,
  class: "p-4"
}
const _hoisted_10 = {
  key: 2,
  class: "p-4 flex items-center"
}

import { PreProcessingFiles } from '@/entityes/invoice/file/loader/loader.type'
import RightSideBar from '@/07-Shared/ui/RightSideBar.vue'
import { DragAndDrop, FileItem, FileFromServerItem, FileFromServerDeleted } from '@/06-Entities/File'
import { computed, defineAsyncComponent } from 'vue'
import { FileLoaderType } from '@/06-Entities/File'
interface IProps {
  loaderDescriber: FileLoaderType
  showDeleteButton?: boolean
}
interface IEmits {
  (e: 'readedFile', value: PreProcessingFiles): void
  (e: 'deleteLocalFile', index: number): void
  (e: 'deleteServerFile', value: number): void
  (e: 'undoDeleteFile'): void
  (e: 'saveFiles'): void
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SidebarLoader',
  props: /*@__PURE__*/_mergeModels({
    loaderDescriber: {},
    showDeleteButton: { type: Boolean, default: false }
  }, {
    "showSidebar": { type: Boolean, ...{ required: true, default: false } },
    "showSidebarModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["readedFile", "deleteLocalFile", "deleteServerFile", "undoDeleteFile", "saveFiles"], ["update:showSidebar"]),
  setup(__props: any, { emit: __emit }) {

const IbDownloadFileInstruction = defineAsyncComponent(
  () => import('@/04-Widgets/LoadFileStep/ui/IbDownloadFileInstruction.vue')
)

const showSidebar = _useModel<boolean>(__props, 'showSidebar')

const props = __props
const isExanteLoaderDescriber = computed<boolean>(
  () => props.loaderDescriber.config.serverTag !== 'exante_virtual_files'
)
const emit = __emit

function dndReadedFiles(value: PreProcessingFiles) {
  emit('readedFile', value)
}
function fileItemDeleteLocalLoadedItem(index: number) {
  emit('deleteLocalFile', index)
} // props.loaderDescriber.onRemoveLocalFile(index)
// function undoDeleteFile() {
//   emit('undoDeleteFile')
// }
// const showFileFromServer = computed(() => isExanteLoaderDescriber && props.loaderDescriber.filesFromServer.length > 0)

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createBlock(RightSideBar, {
    modelValue: showSidebar.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((showSidebar).value = $event))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: props.loaderDescriber.config.logo,
            alt: "plus_icon",
            class: "logo-img mr-2"
          }, null, 8, _hoisted_3),
          (props.loaderDescriber.config.serverTag === 'exante_virtual_files')
            ? (_openBlock(), _createElementBlock("h3", _hoisted_4, "Инструкция для клиентов Exante"))
            : (_openBlock(), _createElementBlock("h3", _hoisted_5, "Загрузить отчеты " + _toDisplayString(props.loaderDescriber.config.title), 1))
        ]),
        _createElementVNode("p", _hoisted_6, _toDisplayString(props.loaderDescriber.config.description), 1)
      ]),
      (isExanteLoaderDescriber.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_unref(DragAndDrop), {
              text: props.loaderDescriber.config.text,
              "file-format": props.loaderDescriber.config.fileFormat,
              onReadedFiles: dndReadedFiles
            }, null, 8, ["text", "file-format"]),
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.loaderDescriber.localFiles.file_names, (item, index) => {
                return (_openBlock(), _createBlock(_unref(FileItem), {
                  name: item,
                  key: 'local_files_' + item,
                  onClose: 
            () => {
              fileItemDeleteLocalLoadedItem(index)
            }
          
                }, null, 8, ["name", "onClose"]))
              }), 128))
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[2] || (_cache[2] = [
            _createElementVNode("iframe", {
              src: "https://www.youtube.com/embed/S2wMoKIKWfk?si=lwHLZJVjyba8nNBC",
              title: "YouTube video player",
              width: "100%",
              height: "300px",
              allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
              allowfullscreen: "",
              loading: "lazy"
            }, null, -1)
          ]))),
      (props.loaderDescriber.deleteFiles.length > 0 || props.loaderDescriber.localFiles.file_names.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(_component_el_button, {
              onClick: _cache[0] || (_cache[0] = 
          () => {
            showSidebar.value = false
            emit('saveFiles')
          }
        ),
              class: "button-main",
              type: "primary"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Сохранить ")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createVNode(_unref(IbDownloadFileInstruction), {
        broker: props.loaderDescriber?.config?.serverTag
      }, null, 8, ["broker"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})