<script lang="ts" setup>
// TODO Mitrich тут надо доделывать
import GoodCard from '../../components/GoodCard'
import BasketCard from '../../components/BasketCard'
import GoodsDescriber, { IGood } from '../../describers/GoodsDescriber'
import { computed, reactive, ref, watch } from 'vue'
import usePolitic from '@/use/usePolitic'
import FinishPaymentDialog from '@/components/Admin/FinishPaymentDialog'
import { useForm } from 'vee-validate'
import { useRouter } from 'vue-router'
import useWindowWidth from '@/use/useWindowWidth'
import useGtagManager from '@/use/useGtagManager'
import NgInvoiceVideo from '@/components/NgInvoiceVideo.vue'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { toTypedSchema } from '@vee-validate/yup'
import * as yup from 'yup'
import { UploadFilled } from '@element-plus/icons-vue'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { ElNotification } from 'element-plus'
import DiscountRequest from '@/components/DiscountRequest.vue'
import { IInvoice } from '@/entityes/invoice/invoice.type'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import FormItem from '@/07-Shared/ui/FormItem.vue'

// Init
export interface IProps {
  id: string | number
}
const props = defineProps<IProps>()
const { isAdmin, isPartner, isCustomer } = usePolitic()
const router = useRouter()
const invoiceStore = useInvoiceStore()
const { initAsync } = useAsyncInvoiceReload()
const { goodSelect, addPromocode, getInvoice, surchargePayment } = useInvoiceRepository()
const { goodsList } = GoodsDescriber()
const { screenLessValue } = useWindowWidth()
const { event } = useGtagManager()
const country = ref('ru')
const yearToCalc = ref(2024)
const baseYearsArray = [2018, 2019, 2020, 2021, 2022, 2023, 2024]
const years = reactive([...baseYearsArray])
const showYearSelect = ref(false)
const addDeductionsSum = ref(0)
const bucketCountCfr = ref(0)
const addDeductionsObject = ref({})

// Tariff
const tariff = ref(invoiceStore.invoice?.tariff || 'base')
const minTariff = ref(false)
const baseTariff = ref(true)
const maxTariff = ref(false)

// modal components
const discountRequest = ref<any | null>(null)
const invoiceVideo = ref(null)

// Invoice
const invoicePayed = invoiceStore.invoice?.pay_status
const amountOperations = computed(() => invoiceStore.invoice?.amount_operations?.Declaration?.[yearToCalc.value] || 0)
const shitPrice = computed(() => invoiceStore.invoice?.all_price?.[yearToCalc.value]?.[tariff.value]?.bucket || 0)
const shitBasePrice = computed(
  () => invoiceStore.invoice?.all_price?.[yearToCalc.value]?.[tariff.value]?.base_bucket || 0
)
const personalPrice = computed(() =>
  !!invoiceStore.invoice ? invoiceStore.invoice?.all_price[yearToCalc.value]?.personal?.personal : 0
)
const surcharge = computed(() => invoiceStore.invoice?.surcharge || 0)
const hasSurcharge = computed(() => surcharge.value !== 0)
const hasErrors = computed(
  () => !!invoiceStore.invoice && Object.keys(invoiceStore.invoice?.warning_and_error?.errors).length !== 0
)
const tinkoffPremiumInvoice = computed(() => invoiceStore.invoice?.email === 'external_premium@tinkoff.tinkoff')
const tinkoffPremiumLink = computed(() => invoiceStore.invoice?.payments?.link)
const promo = computed(() => {
  if (!!invoiceStore.invoice && Object.keys(invoiceStore.invoice?.promo).length > 0) {
    return {
      percent: `${invoiceStore.invoice?.promo?.discount_percent}%*`,
      expirationDate: invoiceStore.invoice?.promo?.active_to?.split('T')[0].split('-').join('-'),
    }
  } else {
    return {
      percent: '',
      expirationDate: '',
    }
  }
})

// Files
const filterHaveFile = computed(() => {
  if (!invoiceStore.invoice) return []
  if (!invoiceStore.invoice.broker_files) return []
  return Object.entries(invoiceStore.invoice.broker_files)
    .map((value) => {
      return {
        have: value[1].length > 0,
        name: value[0],
      }
    })
    .filter((item) => item.have)
})

const hasTinkoffOnly = computed<boolean>(() => {
  if (!invoiceStore.invoice) return false
  if (!invoiceStore.invoice.broker_files) return false
  return Object.entries(invoiceStore.invoice.broker_files).some(
    (value) => value[0] === 'tinkoff_files' && value[1].length > 0
  )
})
const hasShitOnly = computed<boolean>(() => {
  if (!invoiceStore.invoice) return false
  if (!invoiceStore.invoice.broker_files) return false
  return Object.entries(invoiceStore.invoice.broker_files).some(
    (value) => value[0] === 'bucket_files' && value[1].length > 0
  )
})
const hasTinkoffAndShitOnly = computed(() => {
  const names = filterHaveFile.value.map((i) => i.name)
  if (names.includes('bucket_files') && names.includes('tinkoff_files')) {
    return names.length === 2
  }
  return false
})
const haveShitFiles = computed(
  () => invoiceStore.invoice?.broker_files?.bucket_files && invoiceStore.invoice?.broker_files?.bucket_files?.length > 0
)

// OTO
const timerOTO = computed(() => invoiceStore.invoice?.oto_timer || 0)
const endOto = computed(() => {
  const date = new Date(Date.now() + timerOTO.value * 1000)
  const day = Number(date.getDate()) > 9 ? date.getDate() : `0${date.getDate()}`
  return `${day}-${date.getMonth() + 1}-${date.getFullYear()}`
})

// PromoForm
const schema = toTypedSchema(
  yup.object({
    promocode: yup.string(),
  })
)
const { handleSubmit } = useForm({
  validationSchema: schema,
  initialValues: {
    promocode: invoiceStore.invoice?.promo?.code || '',
  },
})

// Lists && Calc
const list = computed(() => {
  if (hasTinkoffOnly.value) {
    return goodsList.filter((i) => i.showGood.value).filter((i) => i.id !== 4)
  } else {
    return goodsList.filter((i) => i.showGood.value)
  }
})
const basketList = computed(() => {
  const names = goodsList.filter((i) => i.inBasket.value).map((i) => i.name)
  if (names.includes('Declaration') && names.includes('Cfr')) {
    goodsList[0].inBasket.value = true
    goodsList[1].inBasket.value = false
    goodsList[3].inBasket.value = false
    return goodsList.filter((i) => i.inBasket.value)
  } else {
    return goodsList.filter((i) => i.inBasket.value)
  }
})
const emptyBasket = computed(() => basketList.value?.every((i) => !i.inBasket.value))
const hasBundle = computed(() => goodsList?.find((i) => i.name === 'bundle')?.inBasket.value)
const cfrBucketSum = computed(() => bucketCountCfr.value * shitPrice.value)
const cfrBucketNoDiscount = computed(() => (shitBasePrice.value ? bucketCountCfr.value * shitBasePrice.value : 0))
const additionalTotal = computed(() => {
  if (hasBundle.value) {
    return addDeductionsSum.value
  } else if (goodsList[1].checked.value) {
    return addDeductionsSum.value
  } else {
    return 0
  }
})
const invoiceTotalPremiumTinkoff = computed(() =>
  invoiceStore.invoice?.payments ? invoiceStore.invoice?.payments.price : 0
)
const noPrices = computed(
  () =>
    !!invoiceStore.invoice &&
    !!invoiceStore.invoice?.all_price &&
    Object.keys(invoiceStore.invoice?.all_price).length === 0
)
const bundleDiscount = computed(() => {
  const prices = invoiceStore.invoice?.all_price
  const bundleTotal = prices?.[yearToCalc.value]?.[tariff.value]?.base_bundle || 0
  const decPlusCfr =
    (prices?.[yearToCalc.value]?.[tariff.value]?.base_declaration || 0) +
    (prices?.[yearToCalc.value]?.[tariff.value]?.base_cfr || 0)
  return decPlusCfr - bundleTotal
})
const invoiceTotal = computed(() => {
  const personal = tariff.value === 'personal' ? personalPrice.value : 0
  const hasDeclarationPdf = basketList.value.map((i) => i.id).includes(3) ? goodsList[2].price.value : 0
  const selectedTotal = basketList.value.reduce((acc, item) => {
    if (hasShitOnly.value) {
      if (item.name === 'bundle') {
        item.price.value = cfrBucketSum.value
      }
      if (item.name === 'Cfr') {
        item.price.value = cfrBucketSum.value
      }
    }
    return acc + item.price.value
  }, 0)
  return hasShitOnly.value
    ? (personal || 0) + selectedTotal + additionalTotal.value + (cfrBucketSum.value - selectedTotal) + hasDeclarationPdf
    : selectedTotal + cfrBucketSum.value + (personal || 0) + additionalTotal.value
})
const createGoodsBody = computed(() => {
  const countGoods = basketList.value
    .map((i) => i.name)
    .reduce((acc: string[], goodName: string) => {
      if (goodName === 'bundle') {
        acc.push(...['Declaration', 'Cfr'])
      }
      if (goodName === 'Declaration') {
        acc.push('Declaration')
      }
      if (goodName === 'Cfr') {
        acc.push('Cfr')
      }
      return acc
    }, [])
  const countServices = basketList.value.reduce(
    (acc: any, service: Record<string, number | string | any>, index: number) => {
      if (!['Declaration', 'Cfr', 'bundle'].includes(service.name)) {
        acc[service.name] = 1
      }
      if (index === basketList.value.length - 1) {
        Object.assign(acc, addDeductionsObject.value)
      }
      return acc
    },
    {}
  )
  return {
    goods: countGoods,
    year_to_calc: yearToCalc.value,
    country: country.value,
    price: invoiceTotal.value,
    tariff: tariff.value,
    services: { ...countServices },
  }
})
const totalText = computed(() => {
  if (hasSurcharge.value) {
    return 'Итого к доплате: '
  } else if (haveShitFiles.value) {
    return 'Предоплата: '
  } else {
    return 'Итого: '
  }
})

function totalAddDeductions(val: { total: number; services: Record<string, number> }) {
  addDeductionsSum.value = val.total
  addDeductionsObject.value = val.services
}
function totalBucketCfr(val: number) {
  bucketCountCfr.value = val
}
function addGood(id: number) {
  const item = goodsList.find((i) => i.id === id)
  if (item && id === 2 && goodsList[3].inBasket.value && item) {
    goodsList[0].inBasket.value = true
    goodsList[3].inBasket.value = false
    goodsList[3].checked.value = true
    item.inBasket.value = false
    item.checked.value = true
  } else if (id === 4 && goodsList[2].inBasket.value && item) {
    goodsList[0].inBasket.value = true
    goodsList[2].inBasket.value = false
    goodsList[2].checked.value = true
    item.inBasket.value = false
    item.checked.value = true
  } else if (item) {
    item.inBasket.value = true
    item.checked.value = true
  }
}
function removeGood(id: number) {
  const item = goodsList.find((i) => i.id === id)
  if (id === 2 && item) {
    if (hasBundle.value) {
      item.inBasket.value = false
      item.checked.value = false
      goodsList[0].inBasket.value = false
      goodsList[0].checked.value = false
      goodsList[3].inBasket.value = true
      goodsList[3].checked.value = true
    }
    item.inBasket.value = false
    item.checked.value = false
  } else if (id === 4 && item) {
    if (hasBundle.value) {
      item.inBasket.value = false
      item.checked.value = false
      goodsList[0].inBasket.value = false
      goodsList[0].checked.value = false
      goodsList[1].inBasket.value = true
      goodsList[1].checked.value = true
    }
    item.inBasket.value = false
    item.checked.value = false
  } else if (item) {
    item.inBasket.value = false
    item.checked.value = false
  }
}
function sendGoodsArray(callbackConfig?: Record<string, (link: string) => Promise<void>>) {
  event('initiateCheckout')
  if (hasSurcharge.value) {
    surchargePayment(props.id)
      .then((res: { [key: string]: string }) => {
        if (isCustomer.value) {
          if (res?.link) window.location.assign(res.link)
        } else {
          callbackConfig?.method?.(res.link)
        }
      })
      .catch((err) => {
        ElNotification({
          title: `Ошибка`,
          message: `перехода на страницу оплаты ${err}`,
          type: 'error',
        })
      })
  } else {
    goodSelect(props.id, createGoodsBody.value)
      .then((res: Record<string, string>) => {
        if (isCustomer.value) {
          if (res?.link) window.location.assign(res.link)
        } else {
          callbackConfig?.method?.(res.link).then(() => {
            if (!invoiceStore.invoice) return
            getInvoice(props.id)
            router.push({ name: 'invoice show' })
          })
        }
      })
      .catch((err) => {
        ElNotification({
          title: `Ошибка`,
          message: `перехода на страницу оплаты ${err}`,
          type: 'error',
        })
      })
  }
}
function getPaymentLink(callbackConfig: Record<string, (link: string) => void>) {
  goodSelect(props.id, createGoodsBody.value).then((res: Record<string, string>) => {
    callbackConfig?.method?.(res?.link)
  })
}
function setupPrices(invoice: IInvoice | null) {
  if (invoice) {
    const prices = invoice?.all_price
    goodsList.forEach((item: IGood) => {
      if (prices && Object.keys(prices).length === 0) {
        item.price.value = 0
        item.basePrice.value = 0
      }
      if (invoice?.surcharge) {
        item.price.value = invoice?.surcharge
      } else {
        if (prices && item.id !== 3 && item.priceTag && item.priceBaseTag) {
          const price = prices[yearToCalc.value][tariff.value][item.priceTag]
          const basePrice = prices[yearToCalc.value][tariff.value][item.priceBaseTag]
          if (price && basePrice) {
            item.price.value = price
            item.basePrice.value = basePrice
          }
        }
        if (item.id === 5) {
          item.price.value = 0
        }
      }
    })
  }
}
const incomesDeductionsWithPrices = computed(() => {
  if (!!invoiceStore?.invoice && !!invoiceStore?.invoice?.pre_selected_add_incomes) {
    return Object.keys(invoiceStore?.invoice?.pre_selected_add_incomes).reduce(
      (acc: Record<string, Record<string, number | undefined>>, key: string) => {
        acc[key] = {
          qty: invoiceStore?.invoice?.all_price?.[yearToCalc.value]?.[tariff.value]?.add_services[key]?.qty,
          price: invoiceStore?.invoice?.all_price?.[yearToCalc.value]?.[tariff.value]?.add_services[key]?.price,
        }
        return acc
      },
      {}
    )
  } else {
    return {}
  }
})
function mergeYears(invoice: IInvoice | null) {
  const allYears = invoice?.all_price
  if (allYears) {
    const yearsKeys = Object.keys(allYears).map((year) => parseInt(year, 10))
    years.splice(0, years.length, ...yearsKeys)
    showYearSelect.value = years.length > 1
  }
}
function mergeTariff() {
  if (tariff.value === 'minimal') {
    minTariff.value = true
    baseTariff.value = false
    maxTariff.value = false
  }
  if (tariff.value === 'base') {
    minTariff.value = false
    baseTariff.value = true
    maxTariff.value = false
  }
  if (tariff.value === 'personal') {
    minTariff.value = false
    baseTariff.value = false
    maxTariff.value = true
  }
}
function saveSelectedGoods(invoice: IInvoice | null) {
  if (invoice?.goods) {
    if (Object.keys(invoice?.goods).length > 0) {
      Object.keys(invoice?.goods).forEach((name) => {
        const goodId = goodsList.find((i) => i.name === name)?.id
        if (goodId) addGood(goodId)
      })
    }
  }
}
const sendPromocode = handleSubmit((promo) => {
  goodSelect(props.id, createGoodsBody.value).then(() => {
    addPromocode(props.id, { ...promo })
      .then(() => {
        ElNotification({
          title: `Промокод`,
          message: 'успешно активирован',
          type: 'success',
        })
      })
      .catch(() => {
        ElNotification({
          title: `Ошибка`,
          message: 'при применении промокода',
          type: 'error',
        })
      })
      .finally(() => {
        getInvoice(props.id).then((res) => {
          initAsync(res)
        })
      })
  })
})
function showDiscountRequest() {
  discountRequest.value.openModal()
}

if (hasTinkoffOnly.value) {
  goodsList[3].inBasket.value = false
  goodsList[4].showGood.value = false
}
// Уведомление о скидочной политике при загрузке страницы GoodsSelection
// if (!hasErrors.value && !politic.isAdmin.value) DiscountModal()
if (hasErrors.value) router.push({ name: 'file_in_invoice' })

watch(yearToCalc, () => {
  if (invoiceStore.invoice) setupPrices(invoiceStore.invoice)
})
watch(tariff, () => {
  if (invoiceStore.invoice) setupPrices(invoiceStore.invoice)
})
watch(minTariff, (val) => {
  if (val) {
    tariff.value = 'minimal'
    baseTariff.value = false
    maxTariff.value = false
  } else {
    if (!maxTariff.value) {
      baseTariff.value = true
    }
  }
})
watch(baseTariff, (val) => {
  if (val) {
    tariff.value = 'base'
    minTariff.value = false
    maxTariff.value = false
  } else {
    if (!maxTariff.value && !minTariff.value) {
      baseTariff.value = true
    }
  }
})
watch(maxTariff, (val) => {
  if (val) {
    tariff.value = 'personal'
    minTariff.value = false
    baseTariff.value = false
  } else {
    if (!minTariff.value) {
      baseTariff.value = true
    }
  }
})
function setupYearToCalc(res: IInvoice | null) {
  if (!res || !res.all_price) return 2024
  const years = Object.keys(res.all_price).map((year) => parseInt(year, 10))
  if (years.includes(res.year_to_calc)) {
    return res.year_to_calc
  } else {
    return years[years.length - 1]
  }
}

// mergeYears()
// setupPrices()
// mergeTariff()

// getInvoice(props.id).then((res) => {
//   initAsync(res).then((res) => {
yearToCalc.value = setupYearToCalc(invoiceStore.invoice)
saveSelectedGoods(invoiceStore.invoice)
mergeYears(invoiceStore.invoice)
setupPrices(invoiceStore.invoice)
mergeTariff()
// })
// })
event('uploadedFile')
</script>

<template>
  <div class="max-w-[1366px] p-4">
    <div v-if="!hasErrors" class="mb-8">
      <div class="flex items-center mb-4" v-if="!hasSurcharge">
        <h1 class="mr-3">Шаг 3. Выберите товары и узнайте их стоимость</h1>
        <ng-invoice-video
          ref="invoiceVideo"
          :show-button="true"
          :video-link="'https://www.youtube.com/embed/8bocQ9Uhkik?si=U5G1gVBB4hn-xpIq'"
        />
      </div>
      <h1 class="mb-4" v-if="hasSurcharge">Требуется доплата</h1>
      <p class="mb-4" v-if="!hasSurcharge">Отметьте нужные позиции и тариф, после чего перейдите к оплате.</p>
      <div class="mt-6">
        <div v-if="hasSurcharge" class="margin-v-s">
          <p>Так как число операций выросло, то для продолжения оформления документов необходима доплата</p>
        </div>
      </div>

      <el-row :gutter="10">
        <!--      Goods-->
        <el-col :xs="24" :sm="24" :lg="12" v-if="!hasSurcharge" :class="[screenLessValue(1200) ? 'mb-8' : '']">
          <div class="step-box">
            <div
              class="pr-4 pl-4 bg-gray border--top flex items-center justify-between"
              :class="[screenLessValue(1250) ? 'flex-box--wrap' : '']"
            >
              <div class="flex items-center" :class="[screenLessValue(1250) ? 'mb-2 text-start' : '']">
                <img class="mr-4 dark-purple-icon" src="@/assets/shop_icon.svg" alt="shop_icon" />
                <h2>Портфель</h2>
              </div>
              <div class="selectors justify-end">
                <div v-if="showYearSelect">
                  <p class="font-weight--extra text-start mb-1">выберите отчетный год</p>
                  <el-select v-model="yearToCalc" placeholder="Выберите год" class="mb-2 min-w-[100px]">
                    <el-option v-for="year in years" :key="year" :label="year" :value="year" />
                  </el-select>
                </div>
                <div v-if="isAdmin">
                  <p class="font-weight--extra text-start mb-1">выберите страну расчета</p>
                  <el-select v-model="country" class="mb-2 min-w-[100px]">
                    <el-option v-for="i in ['ru', 'uk', 'kz', 'se', 'eu']" :key="i" :label="i" :value="i" />
                  </el-select>
                </div>
              </div>
            </div>
            <div class="p-4" v-if="!!invoiceStore.invoice">
              <good-card
                v-for="i in list"
                :key="i.id"
                :in-basket="i.checked.value"
                :good="i"
                :files="filterHaveFile"
                :incomes-deductions="incomesDeductionsWithPrices"
                @add_to_basket="addGood"
                @remove_from_basket="removeGood"
                @count_add="totalAddDeductions"
                @count_bucket_cfr="totalBucketCfr"
                :year="yearToCalc"
                :shit-base-price="shitBasePrice"
                :has-bundle="hasBundle"
                :amount-operations="amountOperations"
                :cfr-bucket-sum="cfrBucketNoDiscount"
              />
              <el-empty description="Вы добавили все товары" v-if="list.length === 0"></el-empty>
            </div>
          </div>
        </el-col>
        <!--      Basket-->
        <el-col :xs="24" :sm="24" :lg="12">
          <div class="step-box sticky-basket">
            <div class="flex items-center p-4 bg-gray border--top">
              <img class="mr-4 dark-purple-icon" src="@/assets/basket_icon.svg" alt="basket_icon" />
              <h2>Стоимость услуг</h2>
            </div>
            <div class="p-4" v-if="!emptyBasket">
              <!--      Tariffs-->
              <div class="mb-4" v-if="!hasSurcharge">
                <h3 class="font-bold mb-4">Тарифы</h3>
                <el-row :gutter="20" style="font-size: 14px; hyphens: auto !important; word-break: break-word">
                  <el-col :xs="24" :sm="8" :md="8" :lg="8">
                    <div
                      class="flex flex-col justify-between text-center tariff-box"
                      :class="minTariff ? 'tariff-box-min' : ''"
                    >
                      <p class="font-bold">Минимальный</p>
                      <p style="line-height: 18px">Максимальная экономия, без тех-поддержки и сопровождения</p>
                      <div class="flex justify-center">
                        <el-checkbox v-model="minTariff" />
                      </div>
                    </div>
                  </el-col>
                  <el-col :xs="24" :sm="8" :md="8" :lg="8">
                    <div
                      class="flex flex-col justify-between text-center tariff-box"
                      :class="baseTariff ? 'tariff-box-base' : ''"
                    >
                      <p class="font-weight--extra">Базовый</p>
                      <p style="line-height: 18px">Помощь тех-поддержки, сопровождение при камеральной проверке</p>
                      <div class="flex-box justify-center">
                        <el-checkbox v-model="baseTariff" />
                      </div>
                    </div>
                  </el-col>
                  <el-col :xs="24" :sm="8" :md="8" :lg="8">
                    <div
                      class="flex flex-col justify-between text-center tariff-box"
                      :class="maxTariff ? 'tariff-box-max' : ''"
                    >
                      <p class="font-bold">Персональный</p>
                      <p style="line-height: 18px">Оформление заказа за вас, полное сопровождение</p>
                      <div class="flex justify-center">
                        <el-checkbox v-model="maxTariff" />
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="el-divider-purple mb-4" v-if="!hasSurcharge"></div>
              <!--      Discounts-->
              <el-row class="mb-2" align="middle" v-if="!hasSurcharge">
                <el-col :md="12" :lg="12">
                  <h3 class="mb-2">Действующие скидки</h3>
                </el-col>
                <el-col :md="4" :lg="4">
                  <h3 class="mb-2 text-center">Размер</h3>
                </el-col>
                <el-col :md="8" :lg="8">
                  <div class="flex justify-end">
                    <h3 class="mb-2">Срок истечения</h3>
                  </div>
                </el-col>
              </el-row>
              <el-row
                v-if="!hasSurcharge && hasBundle && !hasShitOnly && !hasTinkoffAndShitOnly && !hasTinkoffOnly"
                class="mb-4"
              >
                <el-col :md="12" :lg="12">
                  <p>Скидка Декларация + ОДС</p>
                </el-col>
                <el-col :md="4" :lg="4">
                  <p class="text-center">₽ {{ bundleDiscount }}</p>
                </el-col>
                <el-col :md="8" :lg="8"> </el-col>
              </el-row>
              <el-row v-if="timerOTO !== 0 && timerOTO !== null" class="mb-4">
                <el-col :md="12" :lg="12">
                  <p>Специальное предложение</p>
                </el-col>
                <el-col :md="4" :lg="4">
                  <p class="text-center">5%*</p>
                </el-col>
                <el-col :md="8" :lg="8">
                  <div class="flex justify-end">
                    <div>
                      <div class="flex justify-end">
                        <p :class="timerOTO * 1000 < 24 * 3600 * 1000 ? 'font-color--red' : 0">{{ endOto }}</p>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row v-if="tariff === 'minimal' && !hasSurcharge" class="mb-4">
                <el-col :md="12" :lg="12">
                  <p>Скидка по тарифу</p>
                </el-col>
                <el-col :md="4" :lg="4">
                  <p class="text-center">25%*</p>
                </el-col>
                <el-col :md="8" :lg="8"> </el-col>
              </el-row>
              <!--      Promocode-->
              <el-row class="mb-4" v-if="tariff !== 'minimal'" align="middle">
                <el-col :md="12" :lg="12">
                  <div v-if="!hasSurcharge && !isPartner">
                    <el-form @submit.prevent="sendPromocode" :validation-schema="schema">
                      <div class="flex">
                        <FormItem name="promocode" v-slot="{ value, onBlur, onInput }" :label="''">
                          <el-input
                            :model-value="value"
                            type="text"
                            name="promocode"
                            @blur="onBlur"
                            @input="onInput"
                            placeholder="Промокод"
                          />
                        </FormItem>
                        <el-tooltip effect="dark" content="Активировать промокод" placement="top">
                          <el-button
                            style="margin-left: -32px; z-index: 1000; width: 30px"
                            type="primary"
                            :icon="UploadFilled"
                            native-type="submit"
                            :disabled="Object.keys(invoiceStore.invoice?.promo || {}).length > 0"
                          />
                        </el-tooltip>
                      </div>
                    </el-form>
                  </div>
                </el-col>
                <el-col :md="4" :lg="4">
                  <p class="text-center">{{ promo.percent }}</p>
                </el-col>
                <el-col :md="8" :lg="8">
                  <div class="flex justify-end">
                    <p>{{ promo.expirationDate.split('T')[0] }}</p>
                  </div>
                </el-col>
              </el-row>
              <div class="mb-4" v-if="tariff !== 'minimal' && !isPartner && !hasTinkoffOnly && !hasSurcharge">
                <div
                  class="font-color--purple cursor-point button--text-hover flex items-center"
                  @click="showDiscountRequest"
                >
                  <el-icon class="mr-2"><Discount /></el-icon>
                  <span>хочу промокод</span>
                </div>
              </div>
              <div class="el-divider-purple margin-v-s" v-if="!hasSurcharge"></div>
              <!--      GoodsInBasket-->
              <div>
                <h3 class="font-weight--extra mb-2">Товары</h3>
                <basket-card
                  v-for="i in basketList"
                  :key="i.name"
                  :good="i"
                  :add-deductions="addDeductionsSum"
                  :has-shit-only="hasShitOnly"
                  :has-tinkoff-only="hasTinkoffOnly"
                  :cfr-bucket-sum="cfrBucketSum"
                  :cfr-bucket-base-sum="cfrBucketNoDiscount"
                  :base-bundle-discount="bundleDiscount"
                />
                <div v-if="tariff === 'personal'" class="flex items-center justify-between mb-4">
                  <p>Персональное обслуживание</p>
                  <p class="font-bold">₽ {{ personalPrice }}</p>
                </div>
              </div>
              <div class="mb-8">
                <div class="flex justify-end items-center mb-2">
                  <div class="el-divider--30 justify-end">
                    <el-divider class="el-divider-30" />
                    <h2 class="font-bold">
                      <span>{{ totalText }}</span>
                      <span v-if="tinkoffPremiumInvoice">₽ {{ invoiceTotalPremiumTinkoff }}</span>
                      <span v-else class="font-color--purple">₽ {{ hasSurcharge ? surcharge : invoiceTotal }}</span>
                    </h2>
                  </div>
                </div>
              </div>
              <el-row>
                <el-col :sm="24" :md="24" :lg="24" class="flex justify-center">
                  <el-tooltip class="item" effect="dark" content="Переход на оплату" placement="top-start">
                    <el-button
                      :disabled="noPrices || invoicePayed"
                      class="button-main"
                      type="success"
                      @click="
                        () => {
                          sendGoodsArray()
                        }
                      "
                      style="height: 50px; font-size: 18px; width: 90%"
                    >
                      <el-icon class="el-icon-credit-card font-weight--extra mr-2" :size="22"><CreditCard /></el-icon>
                      {{ hasSurcharge ? 'Доплатить' : 'Перейти к оплате' }}
                      {{ `₽ ` + invoiceTotal }}
                    </el-button>
                  </el-tooltip>
                </el-col>
              </el-row>
              <div class="text-center font-color--deep-gray font-bold" v-if="!hasSurcharge" style="font-size: 12px">
                <p>*Скидка распространяется на расчёт декларации и ОДС для брокеров и банков</p>
              </div>
              <finish-payment-dialog
                class="margin-v-s"
                v-if="!isCustomer"
                :id="id"
                @send="sendGoodsArray"
                @get-link="getPaymentLink"
                :tinkoff-link="tinkoffPremiumLink"
                :is-tinkoff-premium="tinkoffPremiumInvoice"
                :is-admin="isAdmin"
              />
            </div>
            <el-empty description="В корзине нет товаров" v-if="emptyBasket"></el-empty>
          </div>
        </el-col>
      </el-row>
    </div>
    <discount-request :id="id" ref="discountRequest" />
  </div>
</template>

<style lang="sass" scoped></style>
