<script lang="ts" setup>
import { useForm, useFormValues } from 'vee-validate'
import { computed } from 'vue'
import ShowOnlyLoadedFiles from '@/07-Shared/ui/ShowOnlyLoadedFiles.vue'
import { useRouter } from 'vue-router'
import OnBoardingDescriber from '@/describers/OnBoardingDescriber'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { ElNotification } from 'element-plus'
import { UploadFilled } from '@element-plus/icons-vue'
import AddIncomesRefundsDescriber from '@/describers/AddIncomesRefundDescriber'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import NgInvoiceVideo from '@/components/NgInvoiceVideo.vue'

export interface IProps {
  id: string | number
}
const props = defineProps<IProps>()
const invoiceStore = useInvoiceStore()
const { createPreAddDeductions } = useInvoiceRepository()
const router = useRouter()
const { incomesDeductionsDescribers: describer, incomes, refunds } = AddIncomesRefundsDescriber()
const { preAddOnBoarding } = OnBoardingDescriber()
const hasErrors = computed(() => Object.keys(invoiceStore.invoice?.warning_and_error?.errors || {}).length !== 0)
const form = computed(() => {
  return describer
    .map((i: { tag: string }) => i.tag)
    .reduce((acc: { [key: string]: number }, tag: string) => {
      if (invoiceStore.invoice?.pre_selected_add_incomes) {
        Object.assign(acc, { [tag]: invoiceStore.invoice?.pre_selected_add_incomes[tag] || 0 })
      } else {
        Object.assign(acc, { [tag]: 0 })
      }
      return acc
    }, {})
})
useForm({
  initialValues: { ...form.value },
})
const formValues = useFormValues()
function deleteItemFromBasket(key: string) {
  formValues.value[key] = 0
}
const basketItems = computed(() => {
  return Object.keys(formValues.value).reduce((acc: { [key: string]: string | number }, key) => {
    if (formValues.value[key] > 0) {
      acc.push({
        value: formValues.value[key],
        tag: key,
        icon: describer.find((i: { [key: string]: number | string }) => i.tag === key)?.icon,
        name: describer.find((i: { [key: string]: number | string }) => i.tag === key)?.name,
      })
    }
    return acc
  }, [])
})
function sendData() {
  const body = basketItems.value.reduce((acc: any, item: any) => {
    return Object.assign(acc, { [item?.tag]: item?.value })
  }, {})
  createPreAddDeductions(props.id, { pre_selected_add_incomes: body })
    .then(() => {
      router.push({ name: 'good_select' })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка cохранения',
        message: `Дополнительных доходов и вычетов ${err}`,
        type: 'error',
      })
    })
}

if (hasErrors.value) router.push({ name: 'file_in_invoice' })
useInvoiceRepository()
  .getInvoice(props.id)
  .then((res) => {
    useAsyncInvoiceReload().initAsync(res)
  })
</script>

<template>
  <div class="mb-10 container-list">
    <div class="flex items-center mb-4">
      <h1 class="mr-4">Шаг 2. Выберите дополнительные доходы и вычеты</h1>
      <ng-invoice-video
        ref="invoiceVideo"
        :show-button="true"
        :video-link="'https://www.youtube.com/embed/8bocQ9Uhkik?si=U5G1gVBB4hn-xpIq'"
      />
    </div>
    <p class="mb-8">
      Выбранные позиции можно будет самостоятельно внести во время оформления заказа или поручить внесение нам.
    </p>
    {{}}
    <el-row :gutter="20">
      <el-col :lg="12">
        <div class="step-box">
          <div class="p-4 bg-gray">
            <h3>{{ incomes.title }}</h3>
          </div>
          <div
            v-for="item in incomes.items"
            :key="item.tag"
            class="flex items-center justify-between p-4"
            :class="formValues[item.tag] > 0 ? 'bg-green' : ''"
          >
            <div class="flex items-center">
              <img :src="item?.icon" alt="close_icon" width="20" class="mr-2 dark-purple-icon" />
              <p class="mr-1">{{ item.name }}</p>
              <question-popover color="#021c1a4d" :width="500">
                <div
                  style="word-break: break-word; hyphens: none; text-align: left"
                  v-html="preAddOnBoarding[item.tag]?.text"
                ></div>
              </question-popover>
            </div>
            <el-tooltip :content="'более одного нельзя'" trigger="hover" :disabled="item?.max !== 1">
              <FormItem :name="item.tag" v-slot="{ value, onBlur, onInput }" :label="''">
                <el-input-number
                  style="width: 104px !important"
                  :min="0"
                  :max="item.max || undefined"
                  :model-value="value"
                  type="text"
                  :name="item.tag"
                  @blur="onBlur"
                  @input="onInput"
                />
              </FormItem>
            </el-tooltip>
          </div>
          <div>
            <div class="p-4 bg-gray">
              <h3>{{ refunds.title }}</h3>
            </div>
            <div v-for="item in refunds.items" :key="item.key">
              <div class="flex items-center p-4">
                <img :src="item?.icon" alt="close_icon" width="20" class="mr-2 dark-purple-icon" />
                <p class="font-bold">{{ item.title }}</p>
              </div>
              <div
                v-for="refund in item.refunds"
                :key="refund.tag"
                class="flex items-center justify-between pl-11 py-4 pr-4"
                :class="formValues[refund.tag] > 0 ? 'bg-green' : ''"
              >
                <span>
                  {{ refund.name }}
                  <question-popover color="#021c1a4d" :width="500">
                    <span
                      style="word-break: break-word; hyphens: none; text-align: left"
                      v-html="preAddOnBoarding[refund.tag]?.text"
                    ></span>
                  </question-popover>
                </span>
                <el-tooltip :content="'более одного нельзя'" trigger="hover" :disabled="refund?.max !== 1">
                  <FormItem :name="refund.tag" v-slot="{ value, onBlur, onInput }" :label="''">
                    <el-input-number
                      style="width: 104px !important"
                      :min="0"
                      :max="refund.max || undefined"
                      :model-value="value"
                      type="text"
                      :name="refund.tag"
                      @blur="onBlur"
                      @input="onInput"
                    />
                  </FormItem>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :lg="12">
        <div class="sticky-basket step-box">
          <div class="p-4 bg-gray">
            <h3>Портфель</h3>
          </div>
          <div class="p-4">
            <div class="mb-4" v-if="invoiceStore.invoice">
              <show-only-loaded-files :broker-files="invoiceStore.invoice?.broker_files" />
            </div>
            <div class="el-divider-purple mb-4"></div>
            <h3 class="mb-8">Выбранные дополнительные доходы и вычеты</h3>
            <el-scrollbar class="mb-4" :height="basketItems.length > 8 ? '300px' : ''">
              <div class="pr-4">
                <div v-if="basketItems.length > 0">
                  <div v-for="(item, index) in basketItems" :key="index" class="mb-8">
                    <div class="flex items-center justify-between">
                      <div class="flex items-center">
                        <img class="mr-2 dark-purple-icon" :src="item?.icon" alt="close_icon" width="20" />
                        <p style="hyphens: none">{{ item.name }}</p>
                      </div>
                      <div class="flex items-center">
                        <p class="font-weight--semi mr-4">{{ item.value }}</p>
                        <img
                          class="button--text-hover delete-icon"
                          @click="deleteItemFromBasket(item.tag)"
                          src="../../assets/delete_icon.svg"
                          alt="close_icon"
                          width="20"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <el-empty description="Вы не добавили ни одного дохода или вычета" v-else />
              </div>
            </el-scrollbar>
            <el-button
              :loading="createPreAddDeductions.indicator.value === true"
              type="success"
              class="button-main"
              @click="sendData"
              :icon="UploadFilled"
            >
              Рассчитать стоимость
            </el-button>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="sass" scoped></style>
