<script lang="ts" setup>
import { computed, toRef } from 'vue'
import LoaderDescriber from '@/06-Entities/File/model/LoaderDescriber'
import useTextFilters from '@/plugins/TextFilters'
import { useRouter } from 'vue-router'
import { BrokerFileType } from '@/entityes/invoice/file/file.type'
import useColors from '@/07-Shared/model/useColors'
import { Files } from '@element-plus/icons-vue'

interface IProps {
  brokerFiles: Partial<Record<BrokerFileType, { id: number; name: string }[]>>
}
const props = defineProps<IProps>()
const { subStringFileName } = useTextFilters()
const loaders = LoaderDescriber().describers
const router = useRouter()
const { colors, isDark } = useColors()
const filesInInvoice: any = toRef(props.brokerFiles)

const onlyLoadedFiles = computed(() => {
  return Object.keys(filesInInvoice.value).reduce((acc, key) => {
    if (filesInInvoice.value[key].length > 0) {
      const loader: any = loaders.find((i) => i.config.serverTag === key)
      if (!!loader) {
        loader.loadedFiles = filesInInvoice.value[key]
      }
      acc.push(loader)
    }
    return acc
  }, <any>[])
})
</script>

<template>
  <div>
    <div class="flex items-center justify-between mb-4">
      <div>
        <h3>Загруженные отчеты</h3>
      </div>
      <div>
        <el-button @click="router.push({ name: 'file_in_invoice' })" link type="primary">
          редактировать отчеты брокеров
        </el-button>
      </div>
    </div>
    <div v-for="(loader, index) in onlyLoadedFiles" :key="index" class="mb-4">
      <div class="flex items-center mb-4">
        <img :src="loader.config.logo" class="mr-2" alt="broker-icon" :width="24" />
        <p class="font-weight--extra">{{ loader.config.title }}</p>
      </div>
      <div v-for="(file, index) in loader.loadedFiles" :key="index" class="pl-4 mb-1">
        <div class="flex items-center">
          <el-icon :color="isDark ? colors.dark.ng_purple : colors.light.ng_purple" size="large" class="mr-2">
            <Files />
          </el-icon>
          <p>{{ subStringFileName(file.name, 30) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
