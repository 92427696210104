<script lang="ts" setup>
import useWindowWidth from '@/use/useWindowWidth'
import { computed, ref, toRef, watch } from 'vue'
import { describers } from '@/06-Entities/File/model/LoaderDescriber'
import { useForm, useFormValues } from 'vee-validate'
import AddIncomesRefundsDescriber from '@/describers/AddIncomesRefundDescriber'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import { toTypedSchema } from '@vee-validate/yup'
import * as yup from 'yup'
import { cloneDeep } from 'lodash'

export interface IProps {
  files: any
  inBasket: boolean
  incomesDeductions: { [key: string]: { qty: number; price: number } }
  year: string | number
  shitBasePrice: string | number
  hasBundle: boolean
  amountOperations: number
  good: any
  cfrBucketSum: string | number
}
export interface IEmits {
  (e: 'add_to_basket', value: number | string): void
  (e: 'remove_from_basket', value: number | string): void
  (e: 'count_bucket_cfr', value: number | string): void
  (e: 'count_add', value: { total: number; services: { [key: string]: number } }): void
}
const props = defineProps<IProps>()
const emit = defineEmits<IEmits>()
const { incomesDeductionsDescribers } = AddIncomesRefundsDescriber()
const tariffsRange = [[0], [1, 15], [16, 50], [51, 200], [201, 800], [801, 1600], [1601, 3000], [3001, 50000]]
const bucketForm = ref(1)
const addedToBasket = ref(props.inBasket)
const formConfig = computed(() => {
  return Object.keys(props.incomesDeductions).reduce((acc: { [tag: string]: number }, tag: string) => {
    Object.assign(acc, { [tag]: props.incomesDeductions[tag].qty })
    return acc
  }, {})
})
const { values } = useForm({
  initialValues: { ...formConfig.value },
})
const formValues = useFormValues()
const currentTariffRange = computed(() => {
  let res = null
  for (const range of tariffsRange) {
    if (props.amountOperations === 0) {
      res = `Тариф: ${props.amountOperations} доходных операций`
    } else {
      const [min, max] = range
      if (props.amountOperations >= min && props.amountOperations <= max) {
        res = `Тариф: ${range.join(' - ')} доходных операций`
      }
    }
  }
  return res
})
const addIncomesDeductionTotal = computed(() => {
  return Object.keys(props.incomesDeductions).reduce((acc, key) => {
    return acc + props.incomesDeductions[key]?.price * formValues.value[key]
  }, 0)
})
const incomesDeductionsObject = computed(() => {
  const items = Object.keys(formValues.value).reduce((acc: { [key: string]: number }, key) => {
    if (formValues.value[key] > 0) {
      acc[key] = formValues.value[key]
    }
    return acc
  }, {})
  return {
    total: addIncomesDeductionTotal.value,
    services: { ...items },
  }
})
const countPrice = computed(() => {
  const res = {} as { [key: string]: number | string }
  if (props.hasBundle) {
    if (props.good.name === 'Cfr') {
      res.basePrice = props.shitBasePrice
    }
    if (props.good.name === 'Declaration') {
      res.basePrice = 0
    }
  }
  if (!props.hasBundle) {
    if (props.inBasket) {
      if (props.good.name === 'Cfr') {
        res.basePrice = props.shitBasePrice
      }
      if (props.good.name === 'Declaration') {
        res.basePrice = props.shitBasePrice
      }
    }
    if (!props.inBasket) {
      res.basePrice = 0
    }
  }
  return res
})

function hasFiles(brokerName: string) {
  return props.files.map((i: { name: string }) => i.name).includes(brokerName)
}
function tooltipForShit(key: string) {
  return ['unknown', 'property_deduction_build', 'property_deduction_mortgage'].includes(key)
}
function sendTotalBucket(val: number) {
  emit('count_bucket_cfr', val)
}

emit('count_add', incomesDeductionsObject.value)
emit('count_bucket_cfr', bucketForm.value)

watch(addIncomesDeductionTotal, () => {
  emit('count_add', incomesDeductionsObject.value)
})
watch(addedToBasket, (val) => {
  if (props.good.name === 'Decalration') {
    emit('count_add', incomesDeductionsObject.value)
  }
  if (val) {
    emit('add_to_basket', props.good.id)
  }
  if (!val) {
    emit('remove_from_basket', props.good.id)
  }
})
</script>

<template>
  <div class="good-card mb-6 card-hover">
    <div class="bg-gray border--top p-4">
      <div class="flex items-center">
        <div class="mr-4">
          <el-checkbox v-model="addedToBasket" />
        </div>
        <div class="flex">
          <h3 class="mr-3">{{ good.title }} {{ year }}</h3>
          <!--          <h2 class="font-bold">}</h2>-->
        </div>
      </div>
      <p>{{ good.description }}</p>
    </div>
    <div v-if="good.name === 'declaration_pdf'" class="flex justify-between font-bold p-4">
      <p>Стоимость</p>
      <p class="font-weight--semi" v-if="good.name === 'declaration_pdf'">₽ {{ good.basePrice }}</p>
    </div>
    <div v-if="good.name === 'Declaration' || good.name === 'Cfr'" class="p-4">
      <div class="mb-2">
        <h4 class="mb-2">Брокерские отчеты</h4>
        <div class="flex justify-between">
          <div>
            <div
              v-if="hasFiles('interactive_brokers_files')"
              class="flex items-center"
              :class="
                hasFiles('freedom_finance_files') || hasFiles('tinkoff_files') || hasFiles('bucket_files') ? 'mb-2' : ''
              "
            >
              <img
                :src="describers.find((i) => i.serverTag === 'interactive_brokers_files')?.logo"
                alt="broker_icon"
                class="logo-img mr-2"
              />
              <p>{{ describers.find((i) => i.serverTag === 'interactive_brokers_files')?.title }}</p>
            </div>
            <div
              v-if="hasFiles('freedom_finance_files')"
              class="flex items-center"
              :class="hasFiles('tinkoff_files') || hasFiles('bucket_files') ? 'margin-v-xs' : ''"
            >
              <img
                :src="describers.find((i) => i.serverTag === 'freedom_finance_files')?.logo"
                alt="broker_icon"
                class="logo-img mr-2"
              />
              <p>{{ describers.find((i) => i.serverTag === 'freedom_finance_files')?.title }}</p>
            </div>
            <div v-if="hasFiles('tinkoff_files') && good.name === 'Declaration'" class="flex-box">
              <img
                :src="describers.find((i) => i.serverTag === 'tinkoff_files')?.logo"
                alt="broker_icon"
                class="logo-img mr-2"
              />
              <p>{{ describers.find((i) => i.serverTag === 'tinkoff_files')?.title }}</p>
            </div>
          </div>
          <div>
            <p
              class="font-weight--semi"
              v-if="
                hasFiles('interactive_brokers_files') ||
                hasFiles('freedom_finance_files') ||
                (hasFiles('tinkoff_files') && good.name === 'Declaration')
              "
              style="margin-top: 6px"
            >
              ₽ {{ good.basePrice }}
            </p>
          </div>
        </div>
        <div
          class="flex justify-end mb-2"
          v-if="
            good.name === 'Declaration' &&
            (hasFiles('interactive_brokers_files') || hasFiles('freedom_finance_files') || hasFiles('tinkoff_files'))
          "
        >
          <p style="font-size: 12px" class="font-color--deep-gray">{{ currentTariffRange }}</p>
        </div>
        <div v-if="hasFiles('bucket_files')" class="flex justify-between items-center mb-2">
          <div class="flex items-center">
            <img
              :src="describers.find((i) => i.serverTag === 'bucket_files')?.logo"
              alt="broker_icon"
              class="logo-img mr-2"
            />
            <p>{{ describers.find((i) => i.serverTag === 'bucket_files')?.title }}</p>
          </div>
          <el-popover placement="top" trigger="hover" :width="300" v-if="good.name === 'Declaration'">
            <template #reference>
              <div>
                <p class="font-color--warning font-bold cursor-pointer button--text-hover">предоплата</p>
              </div>
            </template>
            <template #default>
              <div>
                <p style="word-break: break-word !important">
                  Обратитесь в тех-поддержку для расчета полной стоимости заказа
                </p>
              </div>
            </template>
          </el-popover>
          <el-input-number
            v-if="good.name === 'Cfr'"
            style="width: 86px !important"
            :min="1"
            size="small"
            v-model="bucketForm"
            @input="sendTotalBucket"
          />
          <div>
            <p class="font-weight--semi" v-if="good.name === 'Declaration'">₽ {{ countPrice.basePrice }}</p>
            <p class="font-weight--semi" v-else>₽ {{ cfrBucketSum }}</p>
          </div>
        </div>
        <div
          v-if="good.name === 'Declaration' && Object.keys(incomesDeductions).length > 0"
          class="el-divider-purple"
        />
      </div>
      <div class="mb-2" v-if="Object.keys(incomesDeductions).length > 0 && good.name === 'Declaration'">
        <h4>Доходы и вычеты</h4>
        <el-row class="margin-v-xs">
          <el-col :md="13" :lg="13" />
          <el-col :md="6" :lg="7" class="flex justify-center">
            <p class="font-bold font-color--purple text-center" style="font-size: 14px">
              Поручить внесение<br />
              НДФЛ Гуру
            </p>
          </el-col>
          <el-col :md="4" :lg="4" class="flex justify-end">
            <p class="font-bold font-color--purple" style="font-size: 14px">Стоимость</p>
          </el-col>
        </el-row>
        <div v-for="(val, key) in incomesDeductions" :key="key" class="mb-2">
          <el-row class="mb-2">
            <el-col :md="13" :lg="13">
              <div class="flex items-center">
                <img
                  :src="incomesDeductionsDescribers.find((i) => i.tag === key)?.icon"
                  alt="close_icon"
                  width="20"
                  class="mr-2 dark-purple-icon"
                />
                {{ incomesDeductionsDescribers.find((i) => i.tag === key)?.name }}
              </div>
            </el-col>
            <el-col :md="6" :lg="7" class="flex justify-center">
              <el-tooltip
                :content="'нельзя внести самостоятельно'"
                placement="top"
                trigger="hover"
                :disabled="!tooltipForShit(key as string)"
              >
                <FormItem :name="key" v-slot="{ value, onInput }" :label="''">
                  <el-input-number
                    style="width: 86px !important"
                    :min="0"
                    :max="val.qty"
                    size="small"
                    :model-value="value"
                    type="text"
                    :name="key"
                    :disabled="
                      key === 'unknown' || key === 'property_deduction_build' || key === 'property_deduction_mortgage'
                    "
                    @input="onInput"
                  />
                </FormItem>
              </el-tooltip>
            </el-col>
            <el-col :md="4" :lg="4" class="font-weight--semi flex justify-end">
              <div>
                <p>
                  {{ formValues[key] > 0 ? `₽ ${incomesDeductions[key]?.price * formValues[key] || 0}` : 'внесу сам' }}
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass"></style>
