import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center p-8 bg-gray border" }

import { ElMessage, ElNotification } from 'element-plus'
import useCopyToClipboard from '@/07-Shared/model/useCopyToClipboard'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { GoodsFilled, CopyDocument, CaretRight } from '@element-plus/icons-vue'
import { useRequestService } from '@/use/useRequestService'

export interface IProps {
  id: string | number
  isAdmin: boolean
  tinkoffLink?: string
  isTinkoffPremium: boolean
}
export interface IEmits {
  (e: 'send', value: any): void
  (e: 'get-link', value: any): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'FinishPaymentDialog',
  props: {
    id: {},
    isAdmin: { type: Boolean },
    tinkoffLink: {},
    isTinkoffPremium: { type: Boolean }
  },
  emits: ["send", "get-link"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit
const invoiceStore = useInvoiceStore()
const { skipPayment } = useInvoiceRepository()
const { copyValue } = useCopyToClipboard()
const { isProgress } = useRequestService()

const freeFinishRequest = () => {
  return new Promise((resolve, reject) => {
    skipPayment(props.id)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

function copyRequest(link: string) {
  navigator.clipboard
    .writeText(link)
    .then(() => {
      ElNotification({
        title: `Ссылка`,
        message: 'скопирована в буфер обмена',
        type: 'success',
      })
    })
    .catch(() => {
      ElNotification({
        title: `Ошибка`,
        message: 'копирования в буфер обмена',
        type: 'error',
      })
    })
}
function finishPayment() {
  emit('send', {
    method: freeFinishRequest,
  })
}

function getLink() {
  emit('get-link', {
    method: copyRequest,
  })
}

function select() {
  emit('send', {
    method: () => {
      ElMessage({
        message: 'Товары выбраны',
        // closable: true,
      })
    },
  })
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_button_group = _resolveComponent("el-button-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_button_group, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_tooltip, {
          class: "item",
          effect: "dark",
          content: "Сохранить товары в корзине",
          placement: "top-start"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              icon: _unref(GoodsFilled),
              plain: "",
              onClick: select
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" Товары ")
              ])),
              _: 1
            }, 8, ["icon"])
          ]),
          _: 1
        }),
        (_ctx.isTinkoffPremium)
          ? (_openBlock(), _createBlock(_component_el_tooltip, {
              key: 0,
              class: "item",
              effect: "dark",
              content: "Скопировать ссылку на оплату",
              placement: "top-start"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  icon: _unref(CopyDocument),
                  plain: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(copyValue)(_ctx.tinkoffLink || '')))
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Ссылка TKassa ")
                  ])),
                  _: 1
                }, 8, ["icon"])
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_el_tooltip, {
              key: 1,
              class: "item",
              effect: "dark",
              content: "Скопировать ссылку на оплату",
              placement: "top-start"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  icon: _unref(CopyDocument),
                  plain: "",
                  onClick: getLink
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Ссылка " + _toDisplayString(_ctx.isAdmin ? '' : 'на оплату'), 1)
                  ]),
                  _: 1
                }, 8, ["icon"])
              ]),
              _: 1
            })),
        (_ctx.isAdmin)
          ? (_openBlock(), _createBlock(_component_el_tooltip, {
              key: 2,
              class: "item",
              effect: "dark",
              content: "Пройти оплату",
              placement: "top-start"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  icon: _unref(CaretRight),
                  loading: _unref(isProgress),
                  type: "primary",
                  onClick: finishPayment
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(invoiceStore)?.invoice?.async ? 'Рассчитывается' : 'Далее'), 1)
                  ]),
                  _: 1
                }, 8, ["icon", "loading"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})