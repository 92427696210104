import TransfersMissingsShorts from '@/03-Pages/TMS/TransfersMissingsShorts.vue'
import GoodsSelection from '@/views/account/GoodsSelection.vue'
import LoadFile from '@/03-Pages/LoadFilePage/LoadFile.vue'
import CheckStepperRouteComponent from './CheckStepperRouteComponent.vue'
import PersonalData from '@/components/Invoice/Declaration/PersonalData.vue'
import ResultFiles from '@/03-Pages/Result/ResultFiles.vue'
import { isAuth } from '@/router/middlewares/isAuth'
import { AdditionalIncomePage } from '@/03-Pages/AdditionalIncomePage'
import { DeductionPage } from '@/03-Pages/DeductionPage'
import IncomeDeductionCountruction from '@/03-Pages/PreAddIncome/IncomeDeductionCountruction.vue'
import { invoiceShowMiddleware } from '@/router/middlewares/invoiceShowMiddleware'
import { CheckAdminRoleMiddleware } from '@/router/middlewares/CheckAdminRoleMiddleware'
import TradeOperations from '@/03-Pages/TradeOperations/TradeOperations.vue'

export default [
  {
    name: 'invoice show',
    path: '',
    component: ResultFiles,
    props: true,
    meta: {
      middleware: [isAuth],
    },
  },
  {
    name: 'file_in_invoice',
    path: 'file_in_invoice',
    component: LoadFile,
    meta: {
      middleware: [isAuth],
    },
  },
  {
    name: 'pre_add_deductions',
    path: 'pre_add_deductions',
    component: IncomeDeductionCountruction,
    props: true,
    meta: {
      middleware: [isAuth],
    },
  },
  {
    name: 'good_select',
    path: 'good_select',
    component: GoodsSelection,
    props: true,
    meta: {
      middleware: [isAuth, invoiceShowMiddleware],
    },
  },
  {
    name: 'tms',
    path: 'declarations/:good_id/tms',
    component: TransfersMissingsShorts,
    props: true,
    meta: {
      middleware: [isAuth],
    },
  },
  {
    name: 'add_incomes',
    path: 'declarations/:good_id/add_incomes',
    component: AdditionalIncomePage,
    props: true,
    meta: {
      middleware: [isAuth],
    },
  },
  {
    name: 'deductions',
    path: 'declarations/:good_id/deductions',
    component: DeductionPage,
    props: true,
    meta: {
      middleware: [isAuth],
    },
  },
  {
    name: 'personal_data',
    path: 'personal_data',
    component: PersonalData,
    props: true,
    meta: {
      middleware: [isAuth],
    },
  },
  // {
  //   name: 'taxplan',
  //   path: 'taxplan/:good_id',
  //   component: TaxPlanCustomerView,
  //   props: true,
  //   meta: {
  //     // middleware: [ReloadInvoiceMiddleware, UserIsNotLoggedInMiddleware],
  //   },
  // },
  {
    name: 'calculated_incomes',
    path: 'calculated_incomes',
    component: TradeOperations,
    props: true,
    meta: {
      middleware: [isAuth, CheckAdminRoleMiddleware],
    },
  },
]
